import { bindable } from 'aurelia-framework';
import { DateTimeUtils as DTU } from '@fonix/web-utils';

export class ReportSchedulePeriod {

  @bindable settings;

  constructor() {
    this.enabled = false;
  }

  settingsChanged(settings) {
    this.enabled = !!settings.schedulePeriod;
  }

  onEnabledChecked(e) {
    let checked = (e && e.target && e.target.checked);

    if (checked) {
      this.settings.schedulePeriod = this.settings.schedulePeriod || this.getDefaultTimes();
    } else {
      this.settings.schedulePeriod = null;
    }
  }

  // getDefaultTimes() {
  //   let getUTCTime = (time) => DTU.format(DTU.toUTC(DTU.parseTime(time)), 'LT')
  //   return {
  //     startTime: getUTCTime('00:00'),
  //     endTime: getUTCTime('23:59')
  //   }
  // }

  getDefaultTimes() {
    return {
      startTime: '00:00',
      endTime: '00:00'
    }
  }
}
