import {
  bindable,
  observable
} from 'aurelia-framework';

import './snackbar.scss';

export class Snackbar {
  @observable enable;
  @bindable type;
  @bindable message;
  @bindable button;
  @bindable onBtnClick;

  constructor() {
    this.enable = false;
    this.show = false;
    this.showTimer = 350;
    this.autoClose = true;
    this.autoCloseTimer = 5000;
    this.type = 'default';
  }

  messageChanged() {
    if (this.message) {
      this.enable = true;
      setTimeout(() => {
        this.show = true;
      }, this.showTimer);
    }
  }

  enableChanged() {
    if (this.enable && this.autoClose) {
      setTimeout(() => {
        this.show = false;
        setTimeout(() => {
          this.enable = false;
          this.message = '';
        }, this.showTimer);
      }, this.autoCloseTimer);
    }
  }
}
