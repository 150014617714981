import { bindable, computedFrom } from 'aurelia-framework';

import './tags-suggestions-list.scss';

export class TagsSuggestionsList {

  @bindable items;
  @bindable maxItems;
  @bindable onItemSelected;

  constructor() {
    this.items = [];
    this.filter = null;
    this.maxItems = null;
    this.onItemSelected = () => {};
  }


  @computedFrom('items', 'filter')
  get listItems() {
    let items = this.items;
    return items.slice(0, this.maxItems || items.length);
  }
}
