import { bindable } from 'aurelia-framework';

export class DlcPoints {

  @bindable customSettings;

  constructor() {
    this.includeExpired = false;
  }

  customSettingsChanged(settings) {
    this.includeExpired = !!this.customSettings.includeExpired;
  }

  onEnabledChecked(e) {
    let checked = (e && e.target && e.target.checked);
    if (checked) {
      this.customSettings.includeExpired = this.includeExpired || false
    } else {
      this.customSettings.includeExpired = false;
    }
  }
}
