import {
  bindable
} from 'aurelia-framework';

import './tag-input.scss';

export class TagInput {
  @bindable tag;
  @bindable disabled;


  @bindable onPasteEmail;
  @bindable onClick;
  @bindable onBlur;
  @bindable onKeyPress;

  constructor() {
    this.tag = {};
    this.disabled = false;
    this.onPaste = this.onPaste.bind(this);
  }

  onPaste(e) {
    if (this.disabled) return;
    e.preventDefault();
    if (e.clipboardData) {
      let text = e.clipboardData.getData('text/plain');
      this.tag.value = text;
      setTimeout(() => {
        this.onPasteEmail();
      }, 100);
    }
  }

  onKeyDown(e, tag) {
    if (this.disabled) return;
    let key = e.which || e.keyCode;

    if ([8, 40, 38].indexOf(key) > -1) {
      return this.onKeyPress(e, tag);
    }

    return true;
  }
}
