import { bindable } from 'aurelia-framework';

export class sortSettings {

  @bindable selectedOptionSortBy;
  @bindable selectedOptionSortOrder;
  @bindable sortByOptions;

  constructor() {
    this.sortByOptions = {};

    this.sortOrderOptions = {
      ascending: 'ascending',
      descending: 'descending'
    }
  }

  bind() {
    this.selectedOptionSortBy = this.selectedOptionSortBy || this.sortByOptions.name;
    this.selectedOptionSortOrder = this.selectedOptionSortOrder || this.sortOrderOptions.name;
  }
}
