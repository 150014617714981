/** @format */

import {
  NewInstance,
  computedFrom,
  observable
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import remindersService, {
  ReminderConfigs,
  ReminderStatus,
  ReminderTypes
} from 'services/api/remindersService';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  DateTimeUtils as DTU,
  ConvertUtils
} from '@fonix/web-utils';

export class EditReminderDetails {
  static inject() {
    return [DialogController, NewInstance.of(ValidationController)];
  }
  @observable reminderSet;
  @observable reminders;

  constructor(_DialogController, _ValidationController) {
    this.dialogController = _DialogController;
    this.validationController = _ValidationController;
    this.remindersService = remindersService;

    this.loading = false;
    this.reminderSet = null;
    this.reminders = [];
    this.selectedReminder = null;
    this.remindersIds = [];
    this.accountUnit = ConvertUtils.getUnit('distance') || {
      name: 'km',
      weight: 0
    };

    this.types = {
      assets: 'assets'
    };
    this.reminderDueDate = null;
    this.reminderDueUsage = null;
    this.reminderDueMileage = null;

    this.closeChecked = false;
    this.errorMessage = null;
  }

  activate(model) {
    this.reminderSet = model.reminderSet;
    this.reminders = model.reminders;
    this.closeChecked = true;


    ValidationRules
      .ensure(d => d.reminderDueUsage).required().withMessageKey('required').when(r => {
        return (this.selectedReminder.metadata.chronometer) ? true : false
      })
      .ensure(d => d.reminderDueMileage).required().withMessageKey('required').when(r => {
        return (this.selectedReminder.metadata.odometer) ? true : false
      })
      .ensure(d => d.reminderDueDate).required().withMessageKey('required')
      .ensure(d => d.reminderDueDate).satisfies(r => DTU.isAfter(r.reminderDueDate, DTU.add(DTU.today()))).withMessageKey('date_after_today')
      .on(this);
  }

  remindersChanged() {
    if (this.reminders && this.reminderSet) {
      if (this.reminderSet.type == ReminderTypes.service && this.reminders[0]) {
        this.selectedReminder = this.reminders[0]

        this.reminderDueUsage = (this.selectedReminder.settings.overdueUsage) ? this.selectedReminder.settings.overdueUsage : null;
        this.reminderDueMileage = (this.selectedReminder.settings.overdueMileage) ? this.selectedReminder.settings.overdueMileage : null;
        this.reminderDueDate = (this.selectedReminder.settings.overdueDate) ? this.selectedReminder.settings.overdueDate : null;
      }

      this.remindersIds = this.reminders.map(r => r.id);
    }
  }

  @computedFrom('loading', 'closeChecked', 'reminderDueUsage', 'reminderDueDate', 'reminderDueMileage')
  get disableConfirm() {
    if (this.loading || (!this.closeChecked)) {
      return true;
    }
    if (!this.reminderDueUsage && !this.reminderDueDate && !this.reminderDueMileage) {
      return true;
    }
    if (this.reminderSet.type == ReminderTypes.service) {
      var errors = false;
      if (this.reminderDueUsage && this.reminderDueUsage <= this.selectedReminder.metadata.chronometer) {
        errors = true;
      }
      if (this.reminderDueMileage && this.reminderDueMileage <= this.selectedReminder.metadata.odometer) {
        errors = true;
      }
      if (this.reminderDueDate && DTU.isBefore(this.reminderDueDate, DTU.today())) {
        errors = true;
      }

      return errors;
    }
  }

  onConfirm() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      let dataDetails = {
        ids: this.remindersIds,
        setId: this.reminderSet.id,
        settings: {}
      }
      if (this.reminderDueUsage) {
        dataDetails.settings.overdueUsage = this.reminderDueUsage;
      }
      if (this.reminderDueMileage) {
        dataDetails.settings.overdueMileage = this.reminderDueMileage;
      }
      if (this.reminderDueDate) {
        dataDetails.settings.overdueDate = this.reminderDueDate;
      }

      this.loading = true;
      return this.remindersService
        .updateMultipleReminders(dataDetails)
        .then(data => {
          this.loading = false;
          this.dialogController.ok(data);
        }).catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onCancel() {
    this.dialogController.cancel();
  }

  onValidate() {
    return this.validationController
      .validate()
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }
}
