import { bindable,computedFrom } from 'aurelia-framework';
import { DateTimeUtils } from '@fonix/web-utils';
import './optional-date.scss';

export class OptionalDate {
  @bindable date;
  @bindable onDateChanged;

  constructor() {
    this.hasDate = false;

    this.pickerOptions = {
      maxDate: DateTimeUtils.add(new Date(), -1, 'day')
    }

    this.modes = {
      default: {
        show: false,
        class: null,
        icon: null,
        action: null
      },
      close: {
        show: true,
        class: 'clear-action',
        icon: 'fi-clear',
        action: this.clear.bind(this)
      }
    };

    this.mode = this.modes.default;
    this.leadIcon = {
      show: true,
      class: '',
      icon: 'fi-calendar-range'
    }
    this.trailIcon = this.mode;

  }

  dateChanged(value) {
    this.mode = (value) ? this.modes.close : this.modes.default;
    this.trailIcon = this.mode;
  }

  clear() {
    this.date = null;
    if(this.onDateChanged) {
      this.onDateChanged(null);
    }
  }
}
