/** @format */

import {
  inject,
  DOM,
  valueConverter,
  // bindingBehavior
} from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { customAttribute } from 'aurelia-templating';

///NOTES

// this class does have feature parity with aurelia-i18n's `t` attribute
// such as t="[htmlattr]key" does not work because of how i18n works.

@customAttribute('tcap')
@inject(DOM.Element, I18N)
export class TCapCustomAttribute {
  constructor(element, i18n) {
    this.element = element;
    this.service = i18n;
  }

  bind() {
    this.update();
  }

  valueChanged() {
    this.update();
  }

  update() {
    let key = this.value;

    if (this.element) {
      this.element.textContent = this.capitalize(this.service.tr(key));
    }
  }

  capitalize(text) {
    let t = text || '';
    return t.charAt(0).toUpperCase() + t.slice(1);
  }

  unbind() {
    // If unbind is called before timeout for subscription is triggered, subscription will be undefined
    if (this.subscription) {
      this.subscription.dispose();
    }
  }
}

@valueConverter('tcap')
export class TCapValueConverter {
  static inject() {
    return [I18N];
  }
  constructor(i18n) {
    this.service = i18n;
  }

  toView(value, options) {
    //enchancement
    if (options) {
      //  THIS IS DEPRECATED - USE I18NEXT INTERPOLATION INSTEAD
      //translate only if string is between [ ]
      if (options.userkey) {
        if (value.indexOf('[') < 0) return value;
        let keys = value.match(/\[(.*?)\]/gi) || [];
        keys.forEach(k => {
          let tr = k.substring(k.indexOf('[') + 1, k.indexOf(']'));
          value = value.replace(k, this.service.tr(tr, options));
        });
        return this.capitalize(value);
      }
    }
    return this.capitalize(this.service.tr(value, options));
  }

  capitalize(str) {
    let t = str || '';
    return t.charAt(0).toUpperCase() + t.slice(1);
  }
}
