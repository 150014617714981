/** @format */

import {
  NewInstance,
  computedFrom,
  observable
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import remindersService, {
  ReminderConfigs,
  ReminderStatus,
  ReminderTypes
} from 'services/api/remindersService';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

export class AddReminderDetails {
  static inject() {
    return [DialogController, NewInstance.of(ValidationController)];
  }
  @observable reminders;

  constructor(_DialogController, _ValidationController) {
    this.dialogController = _DialogController;
    this.validationController = _ValidationController;
    this.remindersService = remindersService;

    this.loading = false;
    this.reminderSet = null;
    this.reminders = [];

    this.contextType = null;
    this.contextIds = [];
    this.context = [];
    this.types = {
      assets: 'assets'
    };
    this.reminderRenewalDate = null;

    this.onAssetListChanged = this.onAssetListChanged.bind(this);
    this.onRenewalDateChanged = this.onRenewalDateChanged.bind(this)

    this.closeChecked = false;
    this.errorMessage = null;
  }

  activate(model) {
    this.reminderSet = model.reminderSet;
    this.reminders = model.reminders;
    this.closeChecked = true;

    if (this.reminderSet.settings.renewalDate) {
      ValidationRules
        .ensure(d => d.renewalDate).required().withMessageKey('required')
        .ensure(d => d.renewalDate).satisfies(r => !DTU.isBefore(r, DTU.add(DTU.today(), 1, 'day'))).withMessageKey('date_after_today')
        .on(this.reminderSet.settings);
    }
  }

  remindersChanged() {
    this.reminders.forEach(r => {
      if (!this.contextType) {
        this.contextType = r.contextType;
      }
      if (this.contextType == r.contextType) {
        this.contextIds.push(r.contextId)
      }
    });
  }

  onAssetListChanged(assets) {
    let ids = (assets || []).filter(x => !x.disabled).map(x => {
      return x.id
    });

    this.context = ids;
  }

  @computedFrom('loading', 'context', 'closeChecked')
  get disableConfirm() {
    return this.loading || (!this.closeChecked) || !this.context.length;
  }

  onConfirm() {
    if (!this.context.length) {
      this.dialogController.ok();
      return;
    }

    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.reminderSet.context = {
        type: this.contextType,
        ids: this.context
      }
      this.reminderSet.setId = this.reminderSet.id;
      this.loading = true;

      return this.remindersService
        .createReminderSet(this.reminderSet)
        .then(data => {
          if (this.oldRenewalDate && this.reminderSet.type == ReminderTypes.renewal) {
            this.reminderSet.settings.renewalDate = this.oldRenewalDate
          }
          this.loading = false;
          this.dialogController.ok(data);
        }).catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate()
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onRenewalDateChanged(value) {
    this.oldRenewalDate = this.reminderSet.settings.renewalDate;
    this.reminderSet.settings.renewalDate = value;
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
