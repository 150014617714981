/** @format */

import { bindable } from 'aurelia-framework';
import './permissions.scss';

const ICONS_PERMISSIONS = {
  administration: 'settings',
  map: 'public',
  reports: 'library_books',
  alerts: 'error'
};

export class Permissions {
  @bindable permissionsList;
  @bindable permissions;
  @bindable roles;

  //using two-way binding instead of changed callback, for now at least.
  // @bindable onPermissionsChanged;

  constructor() {
    this.modules = [];

    this.permissionList = [];
    this.permissions = [];

    this.onModuleClick = this.onModuleClick.bind(this);
    this.onModuleExpand = this.onModuleExpand.bind(this);
    this.onFeatureChanged = this.onFeatureChanged.bind(this);
  }

  permissionsListChanged(permissions) {
    let _permissions = permissions || [];
    let modules = _permissions.filter(p => p.isModule);

    this.modules = modules.map(m => {
      let features = _permissions
        .filter(p => p.isFeature)
        .filter(p => p.permissionParent == m.permissionId);

      return {
        id: m.id,
        name: m.name,
        features: features,
        icon: ICONS_PERMISSIONS[m.permissionId],
        active: false,
        expanded: false,
        locked: false
      };
    });

    //refresh list with current permissions
    this.permissionsChanged(this.permissions);
  }

  permissionsChanged(permissions) {
    let _permissions = permissions || [];

    this.modules.forEach(m => {
      m.active = _permissions.find(x => x === m.id);
      m.features.forEach(f => {
        f.active = !!_permissions.find(x => x === f.id);
        if (f.active) m.active = true;
      });
    });
  }

  rolesChanged(roles) {
    let _roles = roles || [];

    //ativate and disable permission from this roles
    let lockPermissions = _roles.map(r => r.permissions);
    lockPermissions = [].concat.apply([], lockPermissions);

    //
    let tryLockPermission = (p, list) => {
      let idx = list.indexOf(p.id);
      let found = idx > -1;
      p.locked = found;
      p.active = found ? found : p.active;
      if (found) list.splice(idx, 1);
      return list;
    };

    //lock modules
    this.modules.forEach(m => {
      lockPermissions = tryLockPermission(m, lockPermissions);
      //repeat for features
      m.features.forEach(
        f => (lockPermissions = tryLockPermission(f, lockPermissions))
      );
    });

    this.updatePermisions();
  }

  onModuleClick(e, pmodule) {
    let action = e.target.dataset.action;
    if (!pmodule.expanded && !action && !pmodule.locked) {
      pmodule.active = !pmodule.active;
      pmodule.features.forEach(f => (f.active = pmodule.active));

      this.updatePermisions();
    }
    return true;
  }

  onModuleExpand(pmodule) {
    pmodule.expanded = !pmodule.expanded;

    if (!pmodule.expanded) {
      if (!pmodule.active) {
        pmodule.active = !!pmodule.features.filter(x => x.active).length;
      }
      this.updatePermisions();
    }
    return false;
  }

  updatePermisions() {
    let newPermissions = [];

    let activModules = this.modules.filter(x => x.active);
    let activeFeatures = activModules.map(x => {
      return x.features.filter(x => x.active);
    });

    //flatten array first, the map
    let activeFeaturesIds = [].concat.apply([], activeFeatures).map(x => {
      return x.id;
    });

    //filter modules with activeFeature in them
    let moduleIds = activModules
      .map(m => m.id)
      .filter(x => activeFeaturesIds.indexOf(x) < 0);

    //merge
    newPermissions = moduleIds.concat(activeFeaturesIds);

    this.permissions = newPermissions;
  }

  onFeatureChanged() {
    this.updatePermisions();
  }
}
