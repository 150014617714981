import {
  bindable,
  observable
} from 'aurelia-framework';

import './snackbar-prompt.scss';

export class SnackbarPrompt {
  @observable enable;
  @bindable type;
  @bindable message;
  @bindable button;
  @bindable onBtnClick;

  constructor() {
    this.enable = false;
    this.show = false;
    this.showTimer = 350;
    this.autoCloseTimer = 5000;
    this.type = 'default';
  }

  messageChanged() {
    if (this.message) {
      this.enable = true;
      setTimeout(() => {
        this.show = true;
      }, this.showTimer);
    }
  }

  closeSnackbar() {
    this.enable = false;
    this.message = '';
  }
}
