/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import {
  DateTimeUtils as DTU,
  ConvertUtils
} from '@fonix/web-utils';
import remindersService, {
  ReminderConfigs,
  ReminderStatus
} from 'services/api/remindersService';

export class ReminderDetails {
  static inject() {
    return [DialogController];
  }
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    //
    this.remindersService = remindersService;
    //
    this.loading = false;
    this.reminderSet = null;
    this.reminder = null;
    this.reminderResolved = false;

    this.accountUnit = ConvertUtils.getUnit('distance') || {
      name: 'km',
      weight: 0
    };

    this.closeChecked = false;
  }

  activate(model) {
    this.reminder = model.reminder;
    this.reminderSet = model.reminderSet;
    this.closeChecked = true;
    this.reminderResolved = (this.reminder.status == ReminderStatus.resolved.id && this.reminder.resolved) ? true : false;

    this.loadReminderDetails();
  }

  loadReminderDetails() {
    this.loading = true;
    this.remindersService.getReminder(this.reminder.id).then(reminder => {
      if (reminder) {
        this.reminder = reminder;
      }

      this.loading = false;
    });
  }

  @computedFrom('loading')
  get disableConfirm() {
    return this.loading || (!this.closeChecked);
  }

  onConfirm() {
    if (!this.reminder.comment && (!this.reminderResolved && !this.reminder.resolved)) {
      this.dialogController.ok();
      return;
    }

    this.loading = true;

    if (this.reminderResolved && !this.reminder.resolved) {
      this.remindersService
        .updateReminderStatus(this.reminder.id, {status: ReminderStatus.resolved.id})
        .then(data => {
          if (!this.reminder.comment) {
            this.loading = false;
            this.dialogController.ok(data);
          }
        })
        .catch(err => {
          this.error = err.message;;
          this.loading = false;
        });
    }

    if (this.reminder.comment) {
      this.remindersService
        .createReminderComment(this.reminder.id, this.reminder.comment)
        .then(data => {
          this.loading = false;
          this.reminder.comment = null;
          this.dialogController.ok(data);
        })
        .catch(err => {
          this.error = err.message;;
          this.loading = false;
        });
    }
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
