import { bindable, computedFrom } from 'aurelia-framework';
import userService from 'services/api/userService';

const TYPES = {
  'assets':'assets',
  'accounnts':'accounts',
  'drivers':'drivers'
}

export class ContextSettings {
  @bindable allowedTypes;
  @bindable context;

  @bindable singleContext;  
  @bindable onChanged;

  constructor() {
    this.onListChanged = this.onListChanged.bind(this);
    this.type = TYPES.assets;
    //
    userService.getSelf().then(u => this.isSuperAdmin = u.isSuperAdmin)
  }
  
  @computedFrom('type')
  get showAssetsList() {
    return this.type === TYPES.assets
  }

  @computedFrom('type')
  get showAccountsList() {
    return this.type === TYPES.accounnts
  }

  @computedFrom('type')  
  get showDriversList() {
    return this.type === TYPES.drivers
  }

  allowedTypesChanged(types) {
    //TODO -- handle multiple types
    this.type = types && types.length ? types[0] : TYPES.assets;
  }

  onListChanged(items) {
    let ids = items.filter(x => x.selected).map(x => x.id);
    this.fireChanged(ids);
  }


  fireChanged(ids) {
    if(this.onChanged) {
      this.onChanged({ items: ids, type: this.type })
    }
  }

}
