import {
  inject,
  computedFrom
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import './prompt-dialog.scss';

@inject(DialogController)
export class PromptDialog {
  constructor(_DialogController) {
    this.dialogController = _DialogController;

    this.title = 'prompt_title';
    this.message = 'prompt_message';
    this.cancel = 'cancel';
    this.confirm = 'confirm';
    this.question = false;
    this.showCancel = true;
    this.showConfirm = true;
    this.checkbox = {
      visible: false,
      value: false,
      label: ''
    };
  }

  activate(model) {
    if (model.title || model.title === null) {
      this.title = model.title;
      this.question = model.question || this.question;
    }
    if (model.message || model.message === null) {
      this.message = model.message;
    }
    if (model.checkbox) {
      this.checkbox = model.checkbox;
    }
    this.cancel = model.cancel || this.cancel;
    this.confirm = model.confirm || this.confirm;

    if (typeof model.showCancel !== "undefined") {
      this.showCancel = model.showCancel;
    }
    if (typeof model.showConfirm !== "undefined") {
      this.showConfirm = model.showConfirm;
    }
  }

  onCancel() {
    this.dialogController.cancel()
  }

  onConfirm() {
    var data = {};
    if(this.checkbox.visible) {
      data.checkbox = this.checkbox.value;
    }
    this.dialogController.ok(data);
  }
}
