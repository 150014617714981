import {
  bindable, observable
} from 'aurelia-framework';
import cctvService from 'services/api/cctvService';
import * as Actions from 'components/cctv/cctv-store-manager/actions.js';
import { Store } from 'aurelia-store';
import video from 'video.js';

import JMuxer from 'jmuxer';
import * as signalR from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';

import './pip-video.scss';



export class pipVideo {
  @bindable activeRoute;
  @observable playing;
  @observable loading;
  @observable waitingAlert;
  @observable channelBusy;

  static inject = [Store];
  constructor(_store) {
    this.cctvService = cctvService;

    this.store = _store;
    this.actions = Actions;

    this.payload = { name: '', context: null };
    this.playing = false;
    this.loading = false;
    this.media = {};
    this.waitingAlert = '';
    this.channelBusy = false;
    this.selectedChannel = {};
    this.detached = false;
  }

  activeRouteChanged(newValue) {
    if (newValue === 'track') {
      this.store.state.video = this.videoChannelEl;
    }
  }

  attached() {
    this.store.state.subscribe((state) => {
      if (state.liveStreamChannel && state.liveStreamChannel.id) {
        this.changeChannel(+state.liveStreamChannel.id);
      }
      else {
        this.selectedChannel = {};
        this.loading = false;
      }

      // VIDEO ACTIONS
      if (state.selectedChannel) {
        this.selectedChannel = state.selectedChannel
      }
      if (state.assetUuid) {
        this.assetUuid = state.assetUuid
      }
      if (state.toggleActions) {
        this.toggleControls();
      }
      if (state.stopMedia) {
        this.stopMedia();
      }
      if (state.toggleFullscreen) {
        this.toggleFullscreen();
      }
      if (state.override) {
        this.onOverride();
      }
      if (state.pip) {
        this.togglePictureInPicture();
      }
      if (state.liveStreamDetached) {
        this.detached = true;
        if (!document.pictureInPictureElement) {
          this.stopMedia();
        }
      }

      state = null;
    });


    this.videoChannelEl.onleavepictureinpicture = e => {
      if (document.pictureInPictureEnabled && this.videoChannelEl && this.detached) {
        this.stopMedia();
      }
    };
  }

  // OBSERVABLES
  playingChanged() {
    this.store.state.playing = this.playing;
  }

  loadingChanged() {
    this.store.state.loading = this.loading;
  }

  channelBusyChanged() {
    this.store.state.channelBusy = this.channelBusy;
  }

  waitingAlertChanged(newVal) {
    this.store.state.waitingAlert = newVal;
    this.timer = setTimeout(() => {
      this.store.state.waitingAlert = "";
    }, 6000);
  }
  ////////////////////




  changeChannel(selectedChannel) {
    if (this.selectedChannel.id && this.selectedChannel.id != selectedChannel && (this.loading || this.playing)) {
      this.stopMedia();
    }
    this.selectedChannel.id = selectedChannel;
    this.store.state.selectedChannel = this.selectedChannel;
  }

  stopMedia = () => {
    if (!this.videoChannelEl) return;

    try {
      if (this.player && Object.keys(this.media).length !== 0 && this.media.constructor === Object) {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
        this.cctvService.stopLiveStreaming(this.media.id, this.media.device, this.media.channel);
        !this.videoChannelEl.paused ? this.player.reset() : '';
        this.videoChannelEl.removeAttribute('src'); // empty source
        this.videoChannelEl.load();

        if ( this.selectedChannel.connection ) {
          this.selectedChannel.connection?.stop().then( () => {
            console.log( 'websocket: disconnected' );
          });
          this.selectedChannel.connection = null;
        }


        this.selectedChannel.path = null;
        this.actions.stopMedia(false);
        this.actions.liveStreamDetached(false);
        this.media = {};
        this.detached = false;
      }
      this.playing = false;
      this.loading = false;
    } catch (e) {
      // DO NOTHING
      console.log(e)
    }
  }

  toggleControls(e, t) {
    this.togglePlayPause();
  }

  togglePlayPause() {
    if (!this.selectedChannel || !this.videoChannelEl) return;
    if (this.playing) {
      this.pauseMedia();
    } else {
      this.startMedia();
    }
  }

  startMedia = () => {
    if (!this.assetUuid || !this.selectedChannel || typeof this.selectedChannel.id === undefined || !this.videoChannelEl) return;
    this.actions.toggleActions(false);
    if (!this.selectedChannel.path) {
      this.loading = true;
      this.cctvService.getStream(this.assetUuid, this.selectedChannel.id).then((stream) => {
        if (stream.id) {
          let payload = { active: true, online: true }
          this.actions.isOnline(payload);
          this.actions.toggleActions(false);
          this.actions.stopMedia(false);
          this.media = {
            device: this.assetUuid,
            channel: this.selectedChannel.id,
            id: stream.id
          }
          this.selectedChannel.path = stream.id;
          this.attachMedia();
        }
      }).catch((e) => {
        console.log(e)
        e.data.request.status == 409 ? this.channelBusy = true : '';
        let payload = { active: true, online: false }
        this.actions.isOnline(payload);
        this.actions.toggleActions(false);

      });
    } else if (this.videoChannelEl.paused) {
      this.playAction();
      this.loading = false;
      this.playing = true;
      this.actions.toggleActions(false);
    }
  }

  pauseMedia() {
    if (!this.selectedChannel || !this.selectedChannel.path || !this.videoChannelEl) return;

    this.videoChannelEl.pause();
    if (document.getElementById('player')) {
      document.getElementById('player').remove();
    }
    this.loading = false;
    this.playing = false;
    this.actions.toggleActions(false);
  }

  attachMedia = () => {
    this.videoChannelEl.volume = (this.mute) ? 0 : 1;
    this.videoChannelEl.loop = false;

    if (this.player) {
      this.player.destroy();
    }

    this.player = new JMuxer({
      node: 'player',
      mode: 'video',
      flushingTime: 1000,
      fps: 10,
      onError: function (data) {
        if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
          this.player.reset();
        }
      }
    });

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(this.cctvService.getStreamVideoUrl(this.selectedChannel.path))
      .withHubProtocol(new MessagePackHubProtocol())
      .build();

    connection
      .start()
      .then(() => {
        console.log("websocket: connected");

        this.selectedChannel.connection = connection;
      })
      .catch(err => console.log("Error while establishing a connection.", err));

    connection.on('Streaming', (data) => {
      this.player.feed({
        video: new Uint8Array(data)
      });
    });
  }

  playAction() {
    this.videoChannelEl.play();
  }

  toggleFullscreen() {
    if (this.videoChannelEl.requestFullScreen) {
      this.videoChannelEl.requestFullScreen();
    } else if (this.videoChannelEl.webkitRequestFullScreen) {
      this.videoChannelEl.webkitRequestFullScreen();
    } else if (this.videoChannelEl.mozRequestFullScreen) {
      this.videoChannelEl.mozRequestFullScreen();
    }
    this.actions.toggleFullscreen(false);
  }

  onOverride() {
    this.loading = true;
    this.channelBusy = false;

    this.cctvService.deleteMediaLease(this.assetUuid, this.selectedChannel.id)
      .then(res => {
        this.cctvService.stopLiveStreaming(res.streamId, this.assetUuid, this.selectedChannel.id)
        .then(() => {
          this.actions.onOverride(false);
          this.startMedia();
        });
      }).catch(e => {
        this.loading = false;
      })
  }

  togglePictureInPicture() {
    this.actions.pip(false);

    if (this.videoChannelEl && this.selectedChannel.path && this.playing) {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      } else {
        this.videoChannelEl.requestPictureInPicture();
      }
    }
  }

  // VIDEO EVENTS
  onWaiting() {
    this.loading = true;
  }

  onPlay() {
    this.playing = true;
    this.loading = false;
  }

  onEnded() {
    if (!this.selectedChannel || !this.selectedChannel.path || !this.videoChannelEl) return;
    if (this.player) {
      this.stopMedia();
    }
  }

  onPause() {
    this.playing = false;
    this.loading = false;
  }

  onCanplaythrough() {
    if (!this.selectedChannel || !this.selectedChannel.path || !this.videoChannelEl) return;

    this.loading = true;
    if (this.videoChannelEl.readyState > 3) {
      this.loading = false;
      this.videoChannelEl.play();
    } else {
      this.videoChannelEl.pause();
    }
  }
}
