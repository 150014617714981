/** @format */

import { bindable, computedFrom } from 'aurelia-framework';
import './resource-owner.scss';

const OWNER_TYPES = {
  everyone: 'everyone',
  user: 'user',
  group: 'group'
};

export class ResourceOwner {
  @bindable owner;
  @bindable onChanged;
  @bindable disabled;

  constructor() {
    this.owner = null;
    this.ownerTypes = OWNER_TYPES;
    this.disabled = false;
    
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
  }

  @computedFrom('owner.type')
  get showGroups() {  
    return this.owner && this.owner.type === OWNER_TYPES.group;
  }

  onTypeChange(value) {
    this.owner = this.owner || {};
    this.owner.type = value;
    this.fireChanged();
  }

  onGroupChange({ id }) {
    this.owner.id = id
    this.fireChanged();
  }

  fireChanged() {
    if (this.onChanged) {
      this.onChanged(this.owner);
    }
  }

}
