import { bindable } from 'aurelia-framework';

export class HourCalculation {

  @bindable selectedOption;

  constructor() {
    this.options = {
      firstIngnition: 'firstIgnition',      
      firstStop: 'firstStop'
    }
  }

  bind() {
    this.selectedOption = this.selectedOption || this.options.firstIngnition;
  }
}
