import {
  inject,
  bindable,
  bindingMode,
  computedFrom
} from 'aurelia-framework';

import './tags-input.scss';
import '../elements/inputs/text-input/text-input.scss';

export class TagsInput {

  @bindable({
    defaultBindingMode: bindingMode.twoWay
  }) tags;
  @bindable suggestions;
  @bindable config;
  @bindable label;
  @bindable disabled;

  //for tags value changes
  @bindable onTagsChanged;

  //for all input(text) value change
  @bindable onValueChange;

  constructor() {
    this.tags = '';
    this.suggestions = [];
    this.config = {};

    this.active = false;
    this._tags = [];
    this._internal = false;

    this.showSuggestions = false;
    this.suggestionIdx = null;

    this.onTagClick = this.onTagClick.bind(this);
    this.onTagBlur = this.onTagBlur.bind(this);
    this.onTagKeyPress = this.onTagKeyPress.bind(this);

    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  @computedFrom('config')
  get cfgMaxSuggestions() {
    return this.config.maxSuggestions || 5;
  }

  get cfgDelimiter() {
    return this.config.delimiter || ',';
  }

  get cfgMaxTags() {
    return this.config.maxTags || null;
  }

  onClick(e) {
    if (this.disabled) return;
    this.defer(() => {

      let edits = this._tags.filter(t => t.editing);
      let t = this._tags.find(t => t.editing)

      if (!t.focus) {
        t.focus = true;
        this.toggleSuggestions(false);
      }

      this.active = true;
    }, 100)
  }

  //bind props
  tagsChanged(value, oldValue) {
    if (this.disabled) return;
    if (!this._internal) {
      let v = (value || '');
      this._tags = (v.split ? v : '').split(this.cfgDelimiter).filter(v => v.length).map(v => {
        return {
          value: v
        }
      })
      this.addNewTag(false);
      this.refreshLabel();
    }
    this._internal = false;
  }

  suggestionsChanged(value) {
    if (this.disabled) return;
    if (!value) return;

    if (value.find(v => typeof v === 'string')) {
      this.suggestions = value.map(s => {
        return {
          label: s
        }
      });
    }
  }

  updateTags() {
    if (this.disabled) return;
    this._internal = true;
    this.tags = this._tags.filter(x => x.value.length)
      .map(t => {
        return t.value
      })
      .join(this.cfgDelimiter);

    if (this.onTagsChanged) {
      this.onTagsChanged(this.tags);
    }
  }

  //methods
  addNewTag(focus = true) {
    if (!this._tags.find(x => x.editing)) {
      this.addTag('', true, focus);
    }
  }

  addTag(value, editing = false, focus = false) {
    this._tags.push({
      value,
      editing,
      focus,
      index: this._tags.length
    });
    this.updateTags();
  }

  removeTag(tag) {
    if (this.disabled) return;
    let idx = this._tags.indexOf(tag);
    if (idx > -1) {
      this._tags.splice(idx, 1);
      this.updateTags();
    }
  }

  editTag(tag, edit) {
    tag.editing = edit;
    if (!edit) {
      this.updateTags();
    }
  }

  //events
  onTagClick(tag, action) {
    if (this.disabled) return;
    if (action === 'delete') {
      this.removeTag(tag);
      return;
    }

    this.editTag(tag, true)
  }

  onTagBlur(e, tag) {
    if (this.disabled) return;
    if (e.explicitOriginalTarget && e.explicitOriginalTarget.data) {
      let selectedItem = e.explicitOriginalTarget.data;
      let result = this.suggestions.find(s => s.value == selectedItem || s.label == selectedItem || s == selectedItem)
      if (result) return;
    }
    this.defer(() => {
      let emptyTag = (!tag.value || !tag.value.length);
      let lastTag = (this._tags.indexOf(tag) === this._tags.length - 1);

      if (!emptyTag) {
        this.editTag(tag, false);

        if (lastTag) {
          this.addNewTag();
        }
        return;
      }

      if (emptyTag && !lastTag) {
        this.removeTag(tag);
      }

      this.toggleSuggestions(false);
      //
      this.refreshLabel();
    }, 100)
    return true;
  }

  onTagKeyPress(e, tag) {
    if (this.disabled) return;
    let key = e.which || e.keyCode;

    if (key === 8) {
      let empty = !(tag.value || '').length;
      let len = this._tags.length;
      if (empty && len > 1) {
        //remove prev to last tag
        this.removeTag(this._tags[len - 2])
        //firefox firing event twice
        return false;
      }
    }

    if (this.cfgMaxTags && this._tags.length > this.cfgMaxTags) return;

    if (key === 13) {
      if (this.suggestionIdx != undefined) {
        this.onSuggestionSelected(this.suggestions[this.suggestionIdx]);
        return false;
      }

      if (tag.value) {
        this.editTag(tag, false);
        this.addNewTag();
        this.toggleSuggestions(null);
      }
      return false;
    }

    if (key === 40 || key === 38) {
      return !this.focusList(key === 40 ? 'down' : 'up');
    }



    this.debouncedfn = this.debouncedfn || this.debounce((k, value) => {
      //
      if (this.onValueChange) {
        this.onValueChange(value)
      }
      this.toggleSuggestions(value)
    }, 400);

    //onKeyPress is fired before the char is registered on the value
    this.defer(() => this.debouncedfn(key, tag.value), 0)


    return true;
  }

  toggleSuggestions(show) {
    this.showSuggestions = !!show;
    // this.suggestionsFilter = value;
  }

  onSuggestionSelected(item, event) {
    let tag = this._tags.find(x => x.editing);
    if (tag && item) {
      tag.value = item.value || item.label || item;
      this.suggestionIdx = null;

      this.editTag(tag, false);
      this.addNewTag();
      this.updateTags();
      this.toggleSuggestions(false);
    }
  }

  onPasteEmail() {
    document.getElementById("blurElement").focus();
  }

  defer(fn, ms = 0) {
    window.setTimeout(fn.bind(this), ms);
  }

  debounce(fn, delay) {
    var timer = null;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  }

  focusList(direction) {
    if (!this.showSuggestions || !this.suggestions || !this.suggestions.length) return;

    if (!this.suggestionIdx && this.suggestionIdx != 0) {
      this.suggestionIdx = 0;
    } else {
      let inc = 1;
      let max = Math.min(this.suggestions.length, this.cfgMaxSuggestions) - 1;

      if (direction == 'up') {
        inc = -1;
      }

      let nexIdx = this.suggestionIdx + inc;
      this.suggestionIdx = Math.max(0, Math.min(nexIdx, max));
    }

    //select suggestion
    this.suggestions.forEach(s => s.selected = false)
    this.suggestions[this.suggestionIdx].selected = true;

    return true;
  }

  refreshLabel() {
    this.active = false;
    if (this._tags.length <= 1) {
      let ft = this._tags[0]
      this.active = ft.value.length || ft.focus
      return;
    } else {
      this._tags.forEach(tag => {
        if (tag.value.length && tag.focus) {
          this.active = true;
          return
        }
      })
    }
  }
}
