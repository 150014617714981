/** @format */

import { bindable, computedFrom } from 'aurelia-framework';
import { TranslationService } from 'services/translationService';
import poiService from 'services/api/areasService';

import { DateTimeUtils } from '@fonix/web-utils';
import './report-settings.scss';

const LAYOUTS = {
  create: 'create',
  shedule: 'schedule'
};
const DATE_RANGES = {
  Y: 'Y',
  CW: 'CW',
  LW: 'LW',
  CM: 'CM',
  LM: 'LM'
};

export class ReportSettings {
  static inject() {
    return [TranslationService];
  }
  //this is a ReportSettingsObject instance
  @bindable settings;
  @bindable showDates;
  @bindable layout;

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    //
    this.poiService = poiService;
    //
    this.error = null;
    this.showDates = true;

    this.layout = LAYOUTS.create;

    this.emailFormats = [
      { id: 'pdf', name: 'PDF' },
      { id: 'html', name: 'HTML' },
      { id: 'csv', name: 'CSV' }
    ];

    this.dtpickerOptions = { maxDate: DateTimeUtils.endOf(new Date()) };
    this.onDateRangeSelected = this.onDateRangeSelected.bind(this);

    this.poiTags = '';
    this.poiSuggestions = [];
    this.onPoiFilterChanged = this.onPoiFilterChanged.bind(this);

    this.onEmailFormatChanged = this.onEmailFormatChanged.bind(this);
    this.onDateFromChanged = this.onDateFromChanged.bind(this);
    this.onOptionalDateChanged = this.onOptionalDateChanged.bind(this);

    this.onContextChanged = this.onContextChanged.bind(this);
  }

  @computedFrom('reportId', 'showDates')
  get hasDates() {
    return this.showDates && [2, 4, 17, 18].indexOf(this.reportId) < 0;
  }

  @computedFrom('reportId')
  get hasCustomSettings() {
    return [1, 4, 6, 9, 10, 13, 14, 17, 18].indexOf(this.reportId) > -1;
  }

  @computedFrom('reportId')
  get hasPoiFilter() {
    return this.reportId === 6;
  }

  //rethink this. Maybe each report should have this settings on it. growing unwieldy
  @computedFrom('reportId')
  get singleContext() {
    return [3].indexOf(this.reportId) > -1;
  }

  @computedFrom('reportId')
  get maxDaysRange() {
    return [3, 5, 8].indexOf(this.reportId) > -1 ? 7 : null;
  }

  @computedFrom('singleContext', 'reportId')
  get allowPagePerContext() {
    return !this.singleContext && [2, 4].indexOf(this.reportId) < 0;
  }

  @computedFrom('reportId')
  get allowedSortByOptions() {
    if([10, 17].indexOf(this.reportId) < 0) return;
    let sortByOptions = {};
    switch (this.reportId) {
      case 10:
      sortByOptions = {
        name: 'name',
        score: 'score'
      };
      break;
      case 17:
      sortByOptions = {
        name: 'name',
        points: 'points',
        lastcheck: 'last_check',
        expires: 'expires'
      };
      break;
    }
    
    return sortByOptions;
  }

  @computedFrom('reportId')
  get allowedContexts() {
    return this.reportId === 17 || this.reportId === 18
      ? ['drivers']
      : this.reportId === 15 || this.reportId === 16
        ? ['accounts']
        : ['assets'];
  }

  @computedFrom('settings.dates.to')
  get dateTo() {
    if (this.settings.dates) {
      return this.settings.dates.to;
    }
  }

  set dateTo(value) {
    this.settings.dates.to = DateTimeUtils.endOf(value);
    this.validateDates(this.dateFrom, this.dateTo)
  }

  @computedFrom('settings.dates.from')
  get dateFrom() {
    if (this.settings.dates) {
      return this.settings.dates.from;
    }
  }

  set dateFrom(value) {
    this.settings.dates = this.settings.dates || {};
    this.settings.dates.from = DateTimeUtils.startOf(value);
    
  }

  @computedFrom('settings.dates.from', 'settings.dates.to')
  get dateErrorMessage() {
    if (this.settings.dates && this.settings.dates.from && this.settings.dates.to) {
      return this.validateDates(this.dateFrom, this.dateTo)
    }
  }

  @computedFrom('maxDaysRange')
  get dateRanges() {
    let r = [
      { name: 'Yesterday', value: DATE_RANGES.Y },
      { name: 'Current Week', value: DATE_RANGES.CW },
      { name: 'Last Week', value: DATE_RANGES.LW }
    ];

    if (!this.maxDaysRange || this.maxDaysRange > 7) {
      r.push(
        { name: 'Current month', value: DATE_RANGES.CM },
        { name: 'Last Month', value: DATE_RANGES.LM }
      );
    }
    return r;
  }

  settingsChanged(value) {
    // console.info('settingsChanged', value)
    if (value) {
      this.reportId = this.settings.reportId;

      this.buildDefaultSettings(this.hasDates);

      if (this.hasPoiFilter && !this.poiSuggestions.length) {
        this.poiService.get().then((pois = []) => {
          this.pois = pois.map(p => p.name);
        });
      }

      let emailcfg = this.settings.emailSettings;
      if (emailcfg) {
        this.emailFormats = this.emailFormats.map(ef => {
          ef.selected = emailcfg.format === ef.id;
          return ef;
        });
      }
    }
  }

  buildDefaultSettings(hasDates) {
    this.settings.contextType = this.settings.contextType || 'assets';
    this.settings.context = this.settings.context || [];

    this.settings.dates =
      this.settings.dates ||
      (hasDates
        ? {
            from: DateTimeUtils.startOf(new Date()),
            to: DateTimeUtils.endOf(new Date())
          }
        : null);

    this.settings.emailSettings = this.settings.emailSettings || {
      format: (this.layout == LAYOUTS.create) ? 'html' : 'pdf',
      addresses: []
    };
    this.settings.preferences = this.settings.preferences || {
      pagePerContext: false
    };

    this.settings.timeZone = DateTimeUtils.detectTZ();
    this.settings.customSettings = this.settings.customSettings || {};
  }

  onContextChanged(context) {
    let { items, type } = context;
    this.settings.context = items;
    this.settings.contextType = type;
  }

  onEmailFormatChanged(format) {
    if (this.settings.emailSettings && format) {
      this.settings.emailSettings.format = format.id;
    }
  }

  onDateFromChanged(date) {
    //set dateto as same as datefrom whenever changed
    this.dateTo = DateTimeUtils.endOf(date);
  }

  onOptionalDateChanged(date) {
    //clear object when date is null(cleared)
    if (date === null) {
      this.settings.dates = null;
      return;
    }
    this.dateFrom = DateTimeUtils.startOf(date);
  }

  //TODO improve validation
  validate() {
    this.settings.error = this.validateDates(this.dateFrom, this.dateTo);
  }

  validateDates(dateFrom, dateTo) {
    if ([4].indexOf(this.reportId) > -1 && dateFrom) {
      if (DateTimeUtils.isBefore(dateFrom, DateTimeUtils.add(DateTimeUtils.today(), -14, 'months'))) {
        return 'report_min_date';
      }
    }
    if (dateFrom && dateTo) {
      //always end of day
      let dateDiff = DateTimeUtils.diff(dateTo, dateFrom, 'months');
      if (dateDiff > 1.02) {
        return 'report_max_month';
      }

      if (this.maxDaysRange) {
        let dayDiff = DateTimeUtils.diff(dateTo, dateFrom, 'days');
        if (dayDiff > this.maxDaysRange) {
          return 'report_max_week';
        }
      }
    }
  }

  onPoiFilterChanged(value) {
    let suggestions = [];

    if ((value || '').length) {
      let regx = new RegExp(value, 'i');
      suggestions = this.pois.filter(p => regx.test(p));
    }

    this.poiSuggestions = suggestions;
  }

  onDateRangeSelected({ value }) {
    // let from = this.dateFrom;
    // let to = this.dateTo;
    let max = new Date();
    let setDate = ([from, to]) => {
      this.dateFrom = from;
      this.dateTo = new Date(Math.min(to, max));
    };

    let startEnd = (unit, prev) => {
      let curr = DateTimeUtils.startOf(new Date(), unit);

      if (prev) {
        curr = DateTimeUtils.subtract(curr, 1, unit);
      }

      return [curr, DateTimeUtils.endOf(curr, unit)];
    };

    switch (DATE_RANGES[value]) {
      case DATE_RANGES.Y:
        {
          let yd = DateTimeUtils.yesterday();
          setDate([yd, DateTimeUtils.endOf(yd)]);
        }
        break;
      case DATE_RANGES.CW:
        setDate(startEnd('week'));
        break;
      case DATE_RANGES.LW:
        setDate(startEnd('week', true));
        break;
      case DATE_RANGES.CM:
        setDate(startEnd('month'));
        break;
      case DATE_RANGES.LM:
        setDate(startEnd('month', true));
        break;
    }
  }
}
