import { bindable } from 'aurelia-framework';

export class ReportTimePeriod {

  @bindable customSettings;

  constructor() {
  }

  customSettingsChanged(settings) {
    this.enabled = !!this.customSettings.customPeriod;
  }

  onEnabledChecked(e) {
    let checked = (e && e.target && e.target.checked);
    if (checked) {
      this.customSettings.customPeriod = this.customSettings.customPeriod || this.getDefaultTimes()
    } else {
      this.customSettings.customPeriod = null;
    }
  }


  getDefaultTimes() {
    // let getUTCTime = (time) => DTU.format(DTU.toUTC(DTU.parseTime(time)),'LT')
    return {
      startTime: '00:00',
      endTime: '23:59'
    }
  }
}
