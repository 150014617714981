import {
  bindable,
  observable,
  computedFrom,
  inject
} from "aurelia-framework";
import {
  TranslationService
} from 'services/translationService';

@inject(TranslationService)
export class MultifactorAuthentication {
  @bindable settings;
  @bindable disabled;
  authImplementations;
  @bindable selectedAuthImplementation;

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    this.disabled = false;
    this.authImplementations = {
      credentials: 'credentials',
      passwordless: 'passwordless',
      multifactor: 'multifactor'
    };

    this.selectedAuthImplementation = this.authImplementations.credentials;
  }

  settingsChanged(value) {
    this.selectedAuthImplementation = (this.settings && this.settings.authImplementation) ? this.settings.authImplementation : this.authImplementations.credentials;
  }

  selectedAuthImplementationChanged(value) {
    if (this.settings) {
      this.settings.authImplementation = value;
    }
  }

  @computedFrom('selectedAuthImplementation')
  get authImplementationMessage() {
    if (this.selectedAuthImplementation) {
      let key = `signin_method_${this.selectedAuthImplementation}`;
      return this.ts.getCap(key);
    }
  }
}
